import Vue from 'vue'
import {
  wxRequest,
  wxRequest_app
} from '@/api/modular/fileStream/user'


// const access_token = Vue.ls.get('WX_access_token')
const agentId = Vue.ls.get('WX_agentId')
const corpId = Vue.ls.get('WX_corpId')

export async function init_wx() {
  if (/MicroMessenger/i.test(navigator.userAgent)) {
    await initWxConfig()
  }
  // await initWxConfig()
  await initWx_agentConfig()
}


function initWx_agentConfig() {
  return new Promise((resolve, reject) => {
    wxRequest_app().then(res => {
      let noncestr = randomString(17);
      let timestamp = Date.parse(new Date()) / 1000;
      let url = window.location.href.split('#')[0];
      let params = {
        "jsapi_ticket": res.data,
        "noncestr": noncestr,
        "timestamp": timestamp,
        "url": url
      };
      console.log('签名参数+++++++++++++',params)
      let signstr = "";
      for (let item in params) {
        signstr += item + "=" + params[item] + "&"
      }
      signstr = signstr.substr(0, signstr.length - 1);

      wx.agentConfig({
        corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: Number(agentId), // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: noncestr, // 必填，生成签名的随机串
        signature: sha1(signstr), // 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: ['selectExternalContact', 'previewFile', 'selectCorpGroupContact'], //必填，传入需要使用的接口名称
        success: function (res) {
          //
          // 注意: 在企业微信平台下，只有 agentConfig 成功回调后，WWOpenData 才会注入到 window 对象上面
          WWOpenData.bindAll(document.querySelectorAll('ww-open-data'))
          // 回调
          resolve(res);
          // resolve(wx);
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级')
          }
          reject(res);
        }
      });
    }).catch(err => {
      reject(err);
    })
  });
}



export function initWxConfig() {
  return new Promise((resolve, reject) => {

    wxRequest().then(res => {
      let noncestr = randomString(17);
      let timestamp = Date.parse(new Date()) / 1000;
      let url = window.location.href.split('#')[0];
      let params = {
        "jsapi_ticket": res.data,
        "noncestr": noncestr,
        "timestamp": timestamp,
        "url": url
      };
      console.log(params)
      let signstr = "";
      for (let item in params) {
        signstr += item + "=" + params[item] + "&"
      }
      signstr = signstr.substr(0, signstr.length - 1);
      wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: corpId, // 必填，企业微信的corpID
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: noncestr, // 必填，生成签名的随机串
        signature: sha1(signstr), // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ["selectEnterpriseContact", 'previewFile'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });

      wx.ready(function () {
        resolve(wx);
        // console.log('success');
      })

      wx.error(res => {
        reject(res);
        // console.log("微信注册失败：" + res);
      })
    }).catch(err => {
      reject(err);
    })
  });
}

/**
 * 企业互联/上下游选人接口
 * @param {{
 * fromDepartmentId： -1 | 0; 表示打开的通讯录从指定的部门开始展示，-1表示打开的通讯录从自己所在部门开始展示, 0表示从最上层开始。移动端，当需要展开的部门不在应用可见范围内，则从应用的可见范围开始展开。
 * mode: 'single'|'multi';
 * type: 'department'|'user'[];
 * selectedDepartmentIds?: string[]; // 非必填，已选部门ID列表。用于多次选人时可重入
 * selectedOpenUserIds: string[]; // 非必填，仅第三方应用使用，自建应用会忽略该字段，已选用户ID列表。用于多次选人时可重入
 * selectedChainDepartmentIds: { corpId: string; departmentId: string; }[]; // 非必填，已选上下游部门ID列表。用于多次选人时可重入
 * selectedChainUserIds: { corpId: string; userId: string; openUserId: string; }[]; // 非必填，已选上下游用户ID列表。用于多次选人时可重入
 * selectedCorpGroupDepartmentIds: { corpId: string; departmentId: string; }[]; // 非必填，已选企业互联部门ID列表。用于多次选人时可重入
 * selectedCorpGroupUserIds: { corpId: string; userId: string; openUserId: string; }[]; // 非必填，已选企业互联用户ID列表。用于多次选人时可重入
 * }} conf api参数
 */
export const selectCorpGroupContact = async (conf) => {
  return new Promise((resolve, reject) => {
    wx.invoke('selectCorpGroupContact', conf, function (res) {
      // eslint-disable-next-line eqeqeq
      if (res.err_msg == 'selectCorpGroupContact:ok') {
        if (typeof res.result === 'string') {
          res.result = JSON.parse(res.result) // 由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
        }
        var selectedDepartmentList = res.result.departmentList // 已选的部门列表
        var selectedUserList = res.result.userList // 已选的成员列表
        var selectedCorpGroupDepartmentList = res.result.corpGroupDepartmentList // 已选的企业互联部门列表
        var selectedCorpGroupUserList = res.result.corpGroupUserList // 已选的已选企业互联成员列表
        var selectedChainDepartmentList = res.result.chainDepartmentList // 已选的上下游部门列表
        var selectedChainUserList = res.result.chainUserList // 已选上下游联用户ID列表
        resolve({
          selectedChainUserList,
          selectedChainDepartmentList,
          selectedCorpGroupUserList,
          selectedCorpGroupDepartmentList,
          selectedDepartmentList,
          selectedUserList
        })
      } else {
        reject(res)
      }
    }
    )
  })
}

export function selectEnterpriseContact(weixin, selectedDepartmentIds, selectedUserIds) {
  return choosePeople(weixin, selectedDepartmentIds, selectedUserIds);
}


const choosePeople = (weixin, selectedDepartmentIds, selectedUserIds) => {
  return new Promise((resolve, reject) => {
    weixin.invoke(
      "selectEnterpriseContact", {
      fromDepartmentId: 0, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
      mode: "multi", // 必填，选择模式，single表示单选，multi表示多选
      type: ["department", "user"], // 必填，选择限制类型，指定department、user中的一个或者多个
      selectedDepartmentIds: selectedDepartmentIds, // 非必填，已选部门ID列表。用于多次选人时可重入，single模式下请勿填入多个id
      selectedUserIds: selectedUserIds, // 非必填，已选用户ID列表。用于多次选人时可重入，single模式下请勿填入多个id
    },
      function (res) {
        if (res.err_msg == "selectEnterpriseContact:ok") {
          if (typeof res.result == "string") {
            res.result = JSON.parse(res.result); //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }

          let DeptUserList = [],
            DepartmentIds = [],
            UserIds = [];
          let departmentList = res.result.departmentList; // 已选的部门列表
          let userList = res.result.userList; // 已选的部门列表

          for (let i in departmentList) {
            DeptUserList.push(departmentList[i].name);
            DepartmentIds.push(departmentList[i].id);
          }

          for (let i in userList) {
            DeptUserList.push(userList[i].name);
            UserIds.push(userList[i].id);
          }

          let selectedList = {
            "deptAndUser": DeptUserList,
            "deptIds": DepartmentIds,
            "userIds": UserIds
          }
          resolve(selectedList);
        } else {
          reject(res.err_msg);
        }
      }
    );
  })
}






//生成随机字符串
function randomString(len) {
  len = len || 32;
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var maxPos = $chars.length;
  var pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}


// 字符串加密成 hex 字符串
function sha1(s) {
  var data = new Uint8Array(encodeUTF8(s))
  var i, j, t;
  var l = ((data.length + 8) >>> 6 << 4) + 16,
    s = new Uint8Array(l << 2);
  s.set(new Uint8Array(data.buffer)), s = new Uint32Array(s.buffer);
  for (t = new DataView(s.buffer), i = 0; i < l; i++) s[i] = t.getUint32(i << 2);
  s[data.length >> 2] |= 0x80 << (24 - (data.length & 3) * 8);
  s[l - 1] = data.length << 3;
  var w = [],
    f = [
      function () {
        return m[1] & m[2] | ~m[1] & m[3];
      },
      function () {
        return m[1] ^ m[2] ^ m[3];
      },
      function () {
        return m[1] & m[2] | m[1] & m[3] | m[2] & m[3];
      },
      function () {
        return m[1] ^ m[2] ^ m[3];
      }
    ],
    rol = function (n, c) {
      return n << c | n >>> (32 - c);
    },
    k = [1518500249, 1859775393, -1894007588, -899497514],
    m = [1732584193, -271733879, null, null, -1009589776];
  m[2] = ~m[0], m[3] = ~m[1];
  for (i = 0; i < s.length; i += 16) {
    var o = m.slice(0);
    for (j = 0; j < 80; j++)
      w[j] = j < 16 ? s[i + j] : rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1),
        t = rol(m[0], 5) + f[j / 20 | 0]() + m[4] + w[j] + k[j / 20 | 0] | 0,
        m[1] = rol(m[1], 30), m.pop(), m.unshift(t);
    for (j = 0; j < 5; j++) m[j] = m[j] + o[j] | 0;
  };
  t = new DataView(new Uint32Array(m).buffer);
  for (var i = 0; i < 5; i++) m[i] = t.getUint32(i << 2);

  var hex = Array.prototype.map.call(new Uint8Array(new Uint32Array(m).buffer), function (e) {
    return (e < 16 ? "0" : "") + e.toString(16);
  }).join("");
  return hex;
}


function encodeUTF8(s) {
  var i, r = [],
    c, x;
  for (i = 0; i < s.length; i++)
    if ((c = s.charCodeAt(i)) < 0x80) r.push(c);
    else if (c < 0x800) r.push(0xC0 + (c >> 6 & 0x1F), 0x80 + (c & 0x3F));
    else {
      if ((x = c ^ 0xD800) >> 10 == 0) //对四字节UTF-16转换为Unicode
        c = (x << 10) + (s.charCodeAt(++i) ^ 0xDC00) + 0x10000,
          r.push(0xF0 + (c >> 18 & 0x7), 0x80 + (c >> 12 & 0x3F));
      else r.push(0xE0 + (c >> 12 & 0xF));
      r.push(0x80 + (c >> 6 & 0x3F), 0x80 + (c & 0x3F));
    };
  return r;
}